import React from 'react'
import * as Styles from './index.module.scss'
import { Contact2 } from '@/components/general/contact-2'
import { Layout } from '@/components/general/layout'
import { Seo } from '@/components/general/seo'
import { Faq } from '@/components/top-page/faq'
import { Feature } from '@/components/top-page/feature'
import { Hero } from '@/components/top-page/hero'
import { News } from '@/components/top-page/news'
import { Plan } from '@/components/top-page/plan'
import { Problem } from '@/components/top-page/problem'
import { Reason } from '@/components/top-page/reason'

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Seo
      templateTitle="%s"
      title="Uniforce（ユニフォース）クラウド決算開示管理サービス"
    />
    <div className={Styles.indexBody}>
      <Hero />
      <Problem />
      <Contact2 />
      <Feature />
      <Reason />
      <Contact2 />
      <Plan />
      <Faq />
      <News />
      <Contact2 />
    </div>
  </Layout>
)

export default IndexPage

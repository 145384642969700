import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import { SectionTitle } from '../general/section-title'
import * as Styles from './problem.module.scss'

export function Problem() {
  return (
    <>
      {/* 未だにこんな業務管理していませんか？ */}
      <section className={Styles.problemWrap}>
        <SectionTitle en="PROBLEM">
          決算開示業務において、
          <br />
          <span>こんな課題抱えていませんか？</span>
        </SectionTitle>

        <p className={Styles.problemWrap__box}>
          <span>
            膨大なタスク量や、複数の開示書類の管理、担当者の専門性、ミスや遅延
          </span>
          が許されないという
          <br className="pc" />
          業務特性に加えて近年、<span>決算早期化・内部統制強化</span>
          が求められることで年々、
          <br className="pc" />
          難易度も高くなっています。そんな決算開示業務の代表的な課題とは。
        </p>

        <ul className={Styles.problems}>
          <li>
            <div className={Styles.problemImage}>
              <div className={Styles.problemImage__inner}>
                <StaticImage
                  src="../../images/problem_image_section-1-1.png"
                  width={175}
                  height={160}
                />
              </div>
            </div>
            <div className={Styles.problems__heading}>
              <h3 className={Styles.problems__border}>
                開示業務の<span>進め方や</span>
                <br />
                <span>正しい開示項目</span>が分からない
              </h3>
            </div>
            <p>
              開示業務の<span>正しい進め方が分からない</span>。
              開示項目や内容が適正なのかも分からない。
              法令改正による変更点を把握するのが大変。
            </p>
          </li>
          <li>
            <div className={Styles.problemImage}>
              <div className={Styles.problemImage__inner}>
                <StaticImage
                  src="../../images/problem_image_section-1-2.png"
                  width={192}
                  height={159}
                />
              </div>
            </div>
            <div className={Styles.problems__heading}>
              <h3 className={Styles.problems__border}>
                <span>属人化している</span>
              </h3>
            </div>
            <p>
              作業量が多いため<span>マニュアル化が出来ない</span>。
              <br />
              作業が複雑で担当できる人員が
              <br />
              限られるため属人化してしまっている。
            </p>
          </li>
          <li>
            <div className={Styles.problemImage}>
              <div className={Styles.problemImage__inner}>
                <StaticImage
                  src="../../images/problem_image_section-1-3.png"
                  width={98}
                  height={170}
                />
              </div>
            </div>
            <div className={Styles.problems__heading}>
              <h3 className={Styles.problems__border}>
                <span>他部署</span>との<span>やり取り</span>が<span>面倒</span>
              </h3>
            </div>
            <p>
              <span>他部署へのレビュー依頼</span>や修正指示の項目が
              <br />
              多く修正範囲も多岐に渡るため、
              <br />
              やり取りが非常に面倒
            </p>
          </li>
        </ul>
      </section>

      {/* benefit */}
      <div className={Styles.benefit}>
        <div className={classNames(Styles.benefitBgTop, 'pc')}>
          <StaticImage
            src="../../images/problem_image_bg-top.png"
            width={2200}
          />
        </div>

        <div className={classNames(Styles.benefitBgTopSp, 'sp')}>
          <div className={Styles.benefitBgTopSp__inner}>
            <StaticImage
              src="../../images/problem_image_bg-top-sp.png"
              width={445}
            />
          </div>
        </div>

        <div className={Styles.benefitInner}>
          <div className={Styles.benefitIntroduction}>
            決算開示業務を
            <span>
              ミスなく効率的に
              <br className="sp" />
              遂行でき、
            </span>
            <br className="pc" />
            <span>
              業務のプロセスを
              <br className="sp" />
              可視化し属人化を防ぐ
            </span>
            仕組みが必要
          </div>

          <div className={Styles.benefitTitle}>
            <SectionTitle as="h2" en="BENEFIT">
              <span>Uniforceなら</span>
              <br className="sp" />
              こんな<span>課題解決</span>ができます
            </SectionTitle>
          </div>
        </div>

        <ul className={classNames(Styles.problems, Styles.benefits)}>
          <li>
            <div className={Styles.problemImage}>
              <div className={Styles.problemImage__inner}>
                <StaticImage
                  src="../../images/benefit_image_section-1-1.png"
                  width={170}
                  height={155}
                />
              </div>
            </div>
            <div className={Styles.benefit__heading}>
              <h3>
                会計基準や法令改正に準じた
                <br />
                <span>ガイドライン機能</span>で<br />
                必要なタスクが
                <span>漏れなく分かる</span>
              </h3>
            </div>
          </li>
          <li>
            <div className={Styles.problemImage}>
              <div className={Styles.problemImage__inner}>
                <StaticImage
                  src="../../images/benefit_image_section-1-2.png"
                  width={182}
                  height={150}
                />
              </div>
            </div>

            <div className={Styles.benefit__heading}>
              <h3>
                <span>決算開示に特化</span>した業務管理ツールで
                <br />
                <span>属人化を防止</span>
              </h3>
            </div>
          </li>
          <li>
            <div className={Styles.problemImage}>
              <div className={Styles.problemImage__inner}>
                <StaticImage
                  src="../../images/benefit_image_section-1-3.png"
                  width={198}
                  height={183}
                />
              </div>
            </div>
            <div className={Styles.benefit__heading}>
              <h3>
                タスクをベースにした<span>レビュー依頼</span>や<br />
                メモ機能で
                <span>
                  他部署や社外担当者との
                  <br />
                  やり取りがスムーズに！
                </span>
              </h3>
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}

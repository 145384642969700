// extracted by mini-css-extract-plugin
export var situationWrap = "feature-module--situationWrap--cNS67";
export var situations = "feature-module--situations--2Ac_q";
export var situationImage = "feature-module--situationImage--epGHE";
export var nextImage = "feature-module--nextImage--13YXd";
export var problem = "feature-module--problem--2BTIq";
export var problemTitle = "feature-module--problemTitle--3KluL";
export var problemImage = "feature-module--problemImage--nuybG";
export var problems = "feature-module--problems--1QbXU";
export var feature = "feature-module--feature--3fXRz";
export var featureBgTop = "feature-module--featureBgTop--1ACVE";
export var featureBgTopSp = "feature-module--featureBgTopSp--1Tcni";
export var featureBgTopSp__inner = "feature-module--featureBgTopSp__inner--2nKiT";
export var featureBgBottom = "feature-module--featureBgBottom--2_m_3";
export var featureImageBottom = "feature-module--featureImageBottom--1oE_D";
export var featureInner = "feature-module--featureInner--YtbJP";
export var featureIntroduction = "feature-module--featureIntroduction--3qpiX";
export var featureTitle = "feature-module--featureTitle--1ormp";
export var featureSectionWrap = "feature-module--featureSectionWrap--2bVs0";
export var featureSection = "feature-module--featureSection--1fmY1";
export var featureSectionInfo = "feature-module--featureSectionInfo--4OPnC";
export var featureSectionSubTitle = "feature-module--featureSectionSubTitle--tPTAu";
export var featureSectionTitle = "feature-module--featureSectionTitle--3lsBQ";
export var rightTitle = "feature-module--rightTitle--2TYtp";
export var leftTitle = "feature-module--leftTitle--3165p";
export var featureSectionReadText = "feature-module--featureSectionReadText--1ts6m";
export var featureSection__illust1 = "feature-module--featureSection__illust1--3bW5X";
export var featureSection__illust1__gif = "feature-module--featureSection__illust1__gif--U_ZkS";
export var featureSection__illust1__mobile_window = "feature-module--featureSection__illust1__mobile_window--CtRRh";
export var featureSection__illust2 = "feature-module--featureSection__illust2--15eUb";
export var featureSection__illust2__gif = "feature-module--featureSection__illust2__gif--1VLpq";
export var featureSection__illust2__mobile_window = "feature-module--featureSection__illust2__mobile_window--3CdaM";
export var featureSection__illust3 = "feature-module--featureSection__illust3--2u4jZ";
export var featureSection__illust3__gif = "feature-module--featureSection__illust3__gif--CTe71";
export var featureSection__illust3__mobile_window = "feature-module--featureSection__illust3__mobile_window--pmFCH";
export var featureSection__illust3__mobile_window__wrap = "feature-module--featureSection__illust3__mobile_window__wrap--VuFXF";
export var featureSection__illust3__mobile_window__wrap__animation = "feature-module--featureSection__illust3__mobile_window__wrap__animation--GMOLY";
export var andMoreWrap = "feature-module--andMoreWrap--21Xmi";
export var andMoreLink = "feature-module--andMoreLink--3ZSGr";
export var efficacyWrap = "feature-module--efficacyWrap--1Mi69";
export var efficacyWrap__image = "feature-module--efficacyWrap__image--1z-uC";
export var efficacy = "feature-module--efficacy--Wyt8m";
export var salesText = "feature-module--salesText--2ARAk";
// extracted by mini-css-extract-plugin
export var problemWrap = "problem-module--problemWrap--1WaKj";
export var problemWrap__box = "problem-module--problemWrap__box--1vzYx";
export var problems = "problem-module--problems--gvB31";
export var problems__heading = "problem-module--problems__heading--3UOFP";
export var benefit__heading = "problem-module--benefit__heading--2fzDY";
export var problemImage = "problem-module--problemImage--3_-Mi";
export var problemImage__inner = "problem-module--problemImage__inner--24lk4";
export var nextImage = "problem-module--nextImage--2yAgF";
export var benefit = "problem-module--benefit--TSw7d";
export var benefitBgTop = "problem-module--benefitBgTop--3Gn8-";
export var benefitBgTopSp = "problem-module--benefitBgTopSp--UMgs5";
export var benefitBgTopSp__inner = "problem-module--benefitBgTopSp__inner--1V6Nb";
export var benefitBgBottom = "problem-module--benefitBgBottom--2EWCS";
export var benefitImageBottom = "problem-module--benefitImageBottom--Rv4DY";
export var benefitInner = "problem-module--benefitInner--_rZiX";
export var benefitIntroduction = "problem-module--benefitIntroduction--2QE-Y";
export var benefitTitle = "problem-module--benefitTitle--JcfFf";
export var benefits = "problem-module--benefits--3vnRp";
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { StaticImage } from 'gatsby-plugin-image'
import { withPrefix } from 'gatsby'
import React from 'react'
import Gif from '../../images/hero-top.gif'
import { pagesPath } from '../../lib/pagesPath'
import * as Styles from './hero.module.scss'

export function Hero() {
  return (
    <div className={Styles.hero}>
      <img src={withPrefix('/hero_icon_logo.svg')} alt="" className={Styles.heroBgLogo} />
      <div className={Styles.heroBgKv__heroicon}>
        <StaticImage src="../../images/hero-icon.png" width={188} />
      </div>
      <div className={Styles.heroBgKv__visual}>
        <div className={Styles.heroBgKv__visual__wrap}>
          <img
            className={Styles.heroBgKv__visual__gif}
            src={Gif}
            alt="Uniforce(ユニフォース)クラウド決算開示管理サービス"
          />
          <StaticImage src="../../images/desktop-background.png" width={900} />

          <div className={Styles.heroBgKv__visual__illust__1}>
            <StaticImage src="../../images/top-illust-1.png" width={286} />
          </div>

          <div className={Styles.heroBgKv__visual__illust__2}>
            <StaticImage src="../../images/top-illust-2.png" width={286} />
          </div>

          <div className={Styles.heroBgKv__visual__illust__3}>
            <StaticImage src="../../images/top-illust-3.png" width={205} />
          </div>
          
          <div className={Styles.heroBgKv__visual__illust__heroicon}>
            <StaticImage src="../../images/hero-icon.png" width={188} />
          </div>
          
        </div>
      </div>
      <div className={Styles.heroLogo}>
        <img
          src={withPrefix('/lp_service_logo2.svg')}
          alt="Uniforce(ユニフォース)クラウド決算開示管理サービス"
          width={152}
        />
      </div>
      <div className={Styles.heroWrap}>
        <p className={Styles.heroSubtitle}>決算開示業務に特化!!</p>
        <div className={Styles.heroTitle}>
          <h1>
            公認会計士がつくった
            <br />
            クラウド決算開示管理サービス
          </h1>
        </div>
        <div className={Styles.heroForm}>
          <div className={Styles.heroMail}>
            <div className={Styles.heroBtn}>
              <AnchorLink to={pagesPath.demo.$url()}>
                無料デモ申し込み
              </AnchorLink>
            </div>
            <div className={Styles.heroBtnClaim}>
              <AnchorLink to={pagesPath.download.$url()}>資料請求</AnchorLink>
            </div>
          </div>
          <p className={Styles.heroAttention}>
            Uniforceの
            <AnchorLink to={pagesPath.rule.$url()}>利用規約</AnchorLink>、
            <AnchorLink to="https://uniforce.co.jp/privacy/">
              プライバシーポリシー
            </AnchorLink>
            に同意の上で、お進みください。
          </p>
        </div>
      </div>
    </div>
  )
}

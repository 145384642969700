import classNames from 'classnames'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import * as Styles from './contact.module.scss'
import { PRIVACY_POLICY_URL } from '@/constants/url'
import { pagesPath } from '@/lib/pagesPath'

export function Contact2(props) {
  return (
    <section {...props} className={classNames(Styles.contact, props.className)}>
      <div className={Styles.bgPc}>
        <StaticImage
          src="../../images/contact_image_bg-2.png"
          width={2200}
          height={420}
        />
      </div>
      <div className={Styles.bgSp}>
        <StaticImage
          src="../../images/contact_image_bg-sp-2.png"
          width={420}
          height={442}
        />
      </div>
      <div className={Styles.contact__title_pc}>
        <p className={Styles.contact__title}>
          決算開示における
          <br />
          <p>「業務効率化・リスクマネジメント」</p>
          Uniforceで実施しませんか？
        </p>
      </div>
      <div className={Styles.contact__title_sp}>
        <p className={Styles.contact__title}>
          決算開示における
          <br />
          <span>「業務効率化・リスクマネジメント」</span>
          Uniforceで実施しませんか？
        </p>
        <StaticImage
          src="../../images/contact_image_point-2-2.png"
          height={186}
        />
      </div>
      <div className={Styles.contact__mail}>
        <div className={Styles.contact__btn}>
          <AnchorLink to={pagesPath.demo.$url()}>無料デモ</AnchorLink>
        </div>
        <div className={Styles.contact__btn_claim}>
          <AnchorLink to={pagesPath.download.$url()}>資料請求</AnchorLink>
        </div>
      </div>
      <p className={Styles.contact__attention}>
        Uniforceの
        <AnchorLink to={pagesPath.rule.$url()}>利用規約</AnchorLink>、
        <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
          プライバシーポリシー
        </a>
        に同意の上で、お進みください。
      </p>
    </section>
  )
}

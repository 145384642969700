import classNames from 'classnames'
import { navigate, withPrefix } from 'gatsby'
import React from 'react'
import * as Styles from './plan-content.module.scss'
import { pagesPath } from '@/lib/pagesPath'

export function PlanContent({ as: Tag = 'h2' }) {
  return (
    <div>
      <section>
        <div className={Styles.wrap}>
          <div className={Styles.basicContainer}>
            <div className={Styles.basicContainer__wrapper}>
              <div className={Styles.header}>
                <div className={Styles.headerDecoration} />
                <p className={Styles.readText}>
                  公認会計士による<span>導入サポート</span>付き
                </p>
                <Tag className={Styles.title}>ベーシックプラン</Tag>
              </div>
              <h3 className={Styles.fullContainer__heading}>
                決算開示業務の効率化や
                <br />
                属人化・開示内容のミス・遅延リスク等の
                <br />
                リスクマネジメントをしたい
              </h3>
              <div className={Styles.apply}>
                <button
                  type="button"
                  onClick={() => navigate(pagesPath.estimateBasic.$url())}
                >
                  このプランの料金をお見積り
                </button>
              </div>
              <p className={Styles.apply__caution}>
                ご利用人数により料金が変動いたします。
              </p>

              <div>
                <details className={Styles.accordion}>
                  <summary>
                    <span>タスク・スケジュール自動作成機能</span>
                  </summary>
                  <ul>
                    <li>タスク自動作成機能</li>
                    <li>スケジュール自動作成機能</li>
                  </ul>
                </details>
                <details className={Styles.accordion}>
                  <summary>
                    <span>チーム・個別タスク管理機能</span>
                  </summary>
                  <ul>
                    <li>オリジナルタスク作成機能</li>
                    <li>タスク変更履歴の保存機能</li>
                    <li>高度なフィルタリング機能</li>
                  </ul>
                </details>
                <details className={Styles.accordion}>
                  <summary>
                    <span>進行管理機能</span>
                  </summary>
                  <ul>
                    <li>リアルタイムレポート機能</li>
                    <li>メンバーごとの進捗管理機能</li>
                    <li>進捗レポート機能</li>
                  </ul>
                </details>
                <details className={Styles.accordion}>
                  <summary>
                    <span>通知・アラートお知らせ機能</span>
                  </summary>
                  <ul>
                    <li>アプリ内通知機能</li>
                    <li>メール通知機能</li>
                    <li>アップデート通知機能</li>
                  </ul>
                </details>
                <details className={Styles.accordion}>
                  <summary>
                    <span>会社・メンバー管理機能</span>
                  </summary>
                  <ul>
                    <li>メンバー追加機能</li>
                    <li>ステータス可視化機能</li>
                    <li>前年度データ移行機能</li>
                  </ul>
                </details>
              </div>

              <div
                className={classNames(
                  Styles.description,
                  Styles.description__blue
                )}
              >
                <dl>
                  <dt>公認会計士による導入サポート</dt>
                  <dd>
                    <ul>
                      <li>初月の導入サポート</li>
                      <li>
                        年4回の定例ミーティング
                        <br />
                        （導入後3ヶ月ごとに打ち合わせ）
                      </li>
                    </ul>
                  </dd>
                </dl>
              </div>
            </div>
          </div>

          <div className={Styles.fullContainer}>
            <div className={Styles.image}>
              <img src={withPrefix('/plan_image_full.svg')} alt="" width="59" height="80" />
            </div>
            <div className={Styles.fullContainer__wrapper}>
              <div className={Styles.header}>
                <div className={Styles.headerDecoration} />
                <p className={Styles.readText}>
                  公認会計士による<span>開示内容支援</span>付き
                </p>
                <Tag className={Styles.title}>アドバンスドプラン</Tag>
              </div>

              <h3 className={Styles.fullContainer__heading}>
                <span>業務効率化とリスクマネジメントに加えて</span>
                <br />
                企業価値向上につながる開示情報の
                <br />
                分析・改善を行いたい
              </h3>

              <div className={Styles.apply}>
                <button
                  type="button"
                  onClick={() => navigate(pagesPath.estimateFull.$url())}
                >
                  このプランの料金をお見積り
                </button>
              </div>

              <p className={Styles.apply__caution}>
                ご利用人数と公認会計士の稼働工数により
                <br />
                料金が変動いたします。
              </p>

              <div className={Styles.basicSupport__wrap}>
                <div className={Styles.basicSupport}>
                  ベーシックプランの全機能
                  <br />
                  公認会計士による導入サポート
                </div>
              </div>

              <div className={Styles.plus}>
                <div>
                  <img src={withPrefix('/plan_icon_plus.svg')} alt="" />
                </div>
              </div>

              <div className={Styles.description}>
                <dl>
                  <dt>公認会計士による導入サポート</dt>
                  <dd>
                    <dl>
                      <dt>毎月</dt>
                      <dd>
                        開示情報の分析や開示内容のアドバイス、
                        <br />
                        開示業務の進行管理支援
                      </dd>
                    </dl>
                    <ul>
                      <li>開示情報・株価トレンドの分析</li>
                      <li>開示内容のアドバイス</li>
                      <li>Uniforceを使用した進行管理支援</li>
                      <li>開示業務に関する質問への回答</li>
                    </ul>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className={Styles.supportContainer}>
          <div className={Styles.supportInner}>
            <h2>
              さらに専属の公認会計士やデザイナーによる以下の業務サポートも可能
            </h2>

            <div className={Styles.supportFlexbox}>
              <div className={Styles.supportBox}>
                <h3>BPO業務支援</h3>
                <ul>
                  <li>各種代行業務</li>
                  <li>各種算定業務</li>
                  <li>制度整備 など...</li>
                </ul>
              </div>
              <div className={Styles.supportBox}>
                <h3>決算説明資料デザイン作成</h3>
                <ul>
                  <li>決算説明資料テンプレート作成</li>
                  <li>
                    投資家向け会社説明資料
                    <br />
                    テンプレート作成
                  </li>
                </ul>
              </div>
            </div>

            <div className={Styles.apply}>
              <button
                type="button"
                onClick={() => navigate(pagesPath.contact.$url())}
              >
                お問合せ
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

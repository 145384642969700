// extracted by mini-css-extract-plugin
export var hero = "hero-module--hero--3wP20";
export var heroLogo = "hero-module--heroLogo--k1jhS";
export var heroWrap = "hero-module--heroWrap--g4Kwv";
export var heroTitle = "hero-module--heroTitle--2Sn2p";
export var heroSubtitle = "hero-module--heroSubtitle--14Kqt";
export var heroForm = "hero-module--heroForm--_5p7e";
export var heroMail = "hero-module--heroMail--2wMGg";
export var heroAttention = "hero-module--heroAttention--1xMF8";
export var heroBtn = "hero-module--heroBtn--16EKY";
export var heroBtnClaim = "hero-module--heroBtnClaim--1H68P";
export var heroBgLogo = "hero-module--heroBgLogo--201HZ";
export var heroBgKv__heroicon = "hero-module--heroBgKv__heroicon--3KR6j";
export var heroBgKv__visual = "hero-module--heroBgKv__visual--Rme5r";
export var heroBgKv__visual__wrap = "hero-module--heroBgKv__visual__wrap--hue4w";
export var heroBgKv__visual__gif = "hero-module--heroBgKv__visual__gif--3S6X6";
export var heroBgKv__visual__illust__1 = "hero-module--heroBgKv__visual__illust__1--278_c";
export var heroBgKv__visual__illust__2 = "hero-module--heroBgKv__visual__illust__2--3084y";
export var heroBgKv__visual__illust__3 = "hero-module--heroBgKv__visual__illust__3--OYa1j";
export var heroBgKv__visual__illust__heroicon = "hero-module--heroBgKv__visual__illust__heroicon--3BUtc";
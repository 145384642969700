import { withPrefix } from 'gatsby'
import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { StaticImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
import { pagesPath } from '../../lib/pagesPath'
import { SectionTitle } from '../general/section-title'
import * as Styles from './feature.module.scss'

export function Feature() {
  return (
    <>
      {/* feature */}
      <div className={Styles.feature}>
        <div className={Styles.featureBgBottom}>
          <div className="pc">
            <StaticImage
              src="../../images/situation_image_bg-bottom.png"
              width={2200}
            />
          </div>
        </div>
        <div className={Styles.featureImageBottom}>
          <StaticImage
            src="../../images/situation_image_bg-bottom-1.png"
            width={1045}
          />
        </div>

        <div className={Styles.featureInner}>
          <div className={Styles.featureTitle}>
            <SectionTitle
              as="h2"
              en="WHAT’S UNIFORCE"
              subText={
                <>
                  公認会計士が設計した決算開示業務に
                  <br className="sp" />
                  特化したサービスで、
                  <br className="pc" />
                  決算開示業務の
                  <br className="sp" />
                  <span>効率化とリスクマネジメントを実現</span>
                  します！
                </>
              }
            >
              <img
                src={withPrefix('/service_logo.svg')}
                alt="Uniforce(ユニフォース)クラウド決算開示管理サービス"
                width={194.43}
                height={44.55}
              />
              の特徴
            </SectionTitle>
          </div>

          <div className={Styles.featureSectionWrap}>
            <div className={Styles.featureSection}>
              <div className={Styles.featureSectionInfo}>
                <p className={Styles.featureSectionSubTitle}>
                  公認会計士が業務効率化と
                  <br className="sp" />
                  ミス軽減を考え設計
                </p>

                <h3
                  className={classNames(
                    Styles.featureSectionTitle,
                    Styles.rightTitle
                  )}
                >
                  決算開示業務ガイドライン機能
                </h3>

                <ul>
                  <li>
                    決算開示に必要な約1,200のタスク・スケジュールを自動作成
                  </li>
                  <li>年度毎に自動更新されるため面倒な初期設定が不要</li>
                  <li>法令改正による変更点の自動アップデート</li>
                </ul>
                <p className={Styles.featureSectionReadText}>
                  各タスクの特性を理解し、多くの企業で開示業務の支援を行ってきた公認会計士が設計しているため、ガイドラインに従えば開示業務を効率的に進められます。また、法令改正に伴った開示項目の変更も都度アップデートしているため、法令改正の調査にかかる工数を削減し、開示情報の不足といったミスを防ぐことが可能です。
                </p>
              </div>
              <div>
                <div
                  className={classNames(
                    Styles.featureSection__illust1,
                    'md-pc'
                  )}
                >
                  <div>
                    <div className={Styles.featureSection__illust1__gif}>
                      <StaticImage
                        // src={'../../images/situation_image_section3-1.jpg'}
                        src={'../../images/situation_image_section3-1.png'}
                        alt="タスクスケジュール自動作成機能"
                        width={658}
                        height={387}
                      />
                    </div>

                    <StaticImage
                      src="../../images/desktop-background.png"
                      width={877}
                      height={541}
                    />
                  </div>

                  <div
                    className={Styles.featureSection__illust1__mobile_window}
                  >
                    <StaticImage
                      src="../../images/situation_image_section3-1-window.png"
                      width={381.75}
                      height={202}
                      alt="タスクスケジュール自動作成機能"
                    />
                  </div>
                </div>

                <div className="md-sp">
                  <StaticImage
                    src="../../images/situation_image_section3-1-sp.png"
                    width={900}
                    alt="タスクスケジュール自動作成機能"
                  />
                </div>
              </div>
            </div>

            <div className={Styles.featureSection}>
              <div className={Styles.featureSectionInfo}>
                <p className={Styles.featureSectionSubTitle}>
                  決算開示業務に特化した設計で
                  <br className="sp" />
                  管理工数を削減！
                </p>

                <h3
                  className={classNames(
                    Styles.featureSectionTitle,
                    Styles.leftTitle
                  )}
                >
                  リアルタイムレポート機能
                </h3>

                <ul>
                  <li>業務の進捗を書類別・担当別でレポート化</li>
                  <li>管理・担当者別々のレポート機能で業務の効率化</li>
                  <li>プロジェクト管理が苦手な方でも進捗管理が可能</li>
                </ul>
                <p className={Styles.featureSectionReadText}>
                  プロジェクト全体の進捗から、遅延タスクの割合、書類別・個人別の進捗状況をリアルタイムに把握することができます。管理者用と担当者用にレポートが分かれているため、担当者の方は自分のタスク状況も瞬時に把握することができる設計になっています。
                </p>
              </div>
              <div>
                <div
                  className={classNames(
                    Styles.featureSection__illust2,
                    'md-pc'
                  )}
                >
                  <div>
                    <div className={Styles.featureSection__illust2__gif}>
                      <StaticImage
                        src={'../../images/situation_image_section3-2.jpg'}
                        alt="決算開示業務プロジェクトの進捗をリアルタイムで確認"
                        width={648}
                        height={365}
                      />
                    </div>

                    <StaticImage
                      src="../../images/desktop-background.png"
                      width={877}
                      height={541}
                    />
                  </div>

                  <div
                    className={Styles.featureSection__illust2__mobile_window}
                  >
                    <StaticImage
                      src="../../images/situation_image_section3-2-window.png"
                      width={381.75}
                      height={203}
                      alt="有価証券報告書のタスク進捗"
                    />
                  </div>
                </div>

                <div className="md-sp">
                  <StaticImage
                    src="../../images/situation_image_section3-2-sp.png"
                    width={877}
                    height={541}
                    alt="有価証券報告書のタスク進捗"
                  />
                </div>
              </div>
            </div>

            <div className={Styles.featureSection}>
              <div className={Styles.featureSectionInfo}>
                <p className={Styles.featureSectionSubTitle}>
                  他部署との
                  <br className="sp" />
                  業務連携を効率化
                </p>

                <h3
                  className={classNames(
                    Styles.featureSectionTitle,
                    Styles.rightTitle
                  )}
                >
                  タスク管理機能
                  <span>（コメント・ウォッチャー・変更履歴）</span>
                </h3>

                <ul>
                  <li>管理者と現場担当間のレビュー依頼や修正指示を一元管理</li>
                  <li>他部署への業務依頼とタスク管理がスムーズ</li>
                  <li>ステータス変更やコメント記載のログが確認可能</li>
                </ul>
                <p className={Styles.featureSectionReadText}>
                  管理部門以外の方や、社外担当者との業務連携が必要な開示業務において、業務を効率的に進めるために、レビュー依頼や修正指示などのやり取りをタスクベースで行い、Uniforceで一元管理することが可能です。
                </p>
              </div>
              <div>
                <div
                  className={classNames(
                    Styles.featureSection__illust3,
                    'md-pc'
                  )}
                >
                  <div>
                    <StaticImage
                      src="../../images/situation_image_section-3.png"
                      width={888}
                      height={556}
                      alt="タスク管理機能"
                    />
                  </div>
                </div>

                <div className="md-sp">
                  <StaticImage
                    src="../../images/situation_image_section-3.png"
                    width={888}
                    height={556}
                    alt="タスク管理機能"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={Styles.efficacyWrap}>
            <div className={classNames(Styles.efficacyWrap__image, 'sp')}>
              <StaticImage
                src="../../images/situation_image_bg-bottom-illust-sp.png"
                width={2200}
              />
            </div>
            <ul className={Styles.efficacy}>
              <li>
                決算開示業務の<span>マニュアル化</span>
              </li>
              <li>
                管理工数の<span>削減</span>
              </li>
              <li>
                業務負荷の<span>軽減</span>
              </li>
              <li>
                <span>属人化防止</span>
              </li>
            </ul>

            <p className={Styles.salesText}>
              Uniforceは決算開示業務における課題を解決し
              <br className="pc" />
              効率化とミス軽減につながるサービスです。
            </p>

            <div className={Styles.andMoreWrap}>
              <AnchorLink
                className={Styles.andMoreLink}
                to={pagesPath.tool.$url()}
              >
                機能の詳細はこちら
              </AnchorLink>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

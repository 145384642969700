import { graphql, useStaticQuery, Link, withPrefix } from 'gatsby'
import React from 'react'
import { SectionTitle } from '../general/section-title'
import * as Styles from './news.module.scss'
import { dayjs } from '@/lib/dayjs'
import { pagesPath } from '@/lib/pagesPath'

export function News() {
  const { allMicrocmsService: information } = useStaticQuery(
    graphql`
      query {
        allMicrocmsService(limit: 3) {
          nodes {
            serviceId
            title
            publishedAt
            category {
              title
            }
          }
        }
      }
    `
  )
  return (
    <section className={Styles.news} id="news">
      <SectionTitle en="INFORMATION" as="h2" gradient>
        お知らせ
      </SectionTitle>
      <ul>
        {information.nodes.map((node) => (
          <li key={node.serviceId}>
            <p className={Styles.news__category}>{node.category.title}</p>
            <p>{node.title}</p>
            <div className={Styles.news__smalltext}>
              <p className={Styles.news__date}>
                {dayjs(node.publishedAt).format('YYYY/MM/DD')}
              </p>
              <Link
                to={pagesPath.news._contentId(node.serviceId).$url()}
                className={Styles.news__newsbtn}
              >
                詳細&emsp;
                {/* <img src={withPrefix('/news_more.svg')} className="news_more" alt="" /> */}
              </Link>
            </div>
          </li>
        ))}
      </ul>
      <p className={Styles.news__btn}>
        <Link to={pagesPath.news.$url()}>一覧を見る</Link>
      </p>
    </section>
  )
}

import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React from 'react'
import { SectionTitle } from '../general/section-title'
import { PlanContent } from '../plan/plan-content'
import * as Styles from './plan.module.scss'
import { pagesPath } from '@/lib/pagesPath'

export function Plan() {
  return (
    <>
      <section className={Styles.price}>
        <SectionTitle
          en="PRICE"
          as="h2"
          gradient
          subText={
            <>
              クラウド決算開示管理サービス【Uniforce】は
              <br />
              <span>２つのプラン</span>をご用意しております。
            </>
          }
        >
          料金プラン
        </SectionTitle>

        <div className={Styles.content}>
          <PlanContent />
        </div>

        <div className={Styles.price__more_btn}>
          <AnchorLink to={pagesPath.plan.$url()}>詳しく見る</AnchorLink>
        </div>
      </section>
    </>
  )
}
